const packages = require("./packages.json");

let total = 0;
let employerTotal = 0;
let employerTotalTaxed = 0;
let employeeTotal = 0;
let bikesNumber = 20;
let selectedPackage = packages[0];
let employerShare = 0.7;

import { main } from "./scripts/fetchGasAvgPrice";
let avgGasPricePerKm = main();

const contentDiv = document.getElementById("content");
window.addEventListener("load", function () {
  setTimeout(() => {
    parent.postMessage(contentDiv.scrollHeight, "*");
  }, 500);
});

const employerTotalSpanElem = document.getElementById("employer-total");
const employerTotalTaxedSpanElem = document.getElementById(
  "employer-total-taxed"
);
const employerTotalPerBikeSpanElem = document.getElementById(
  "employer-total-per-bike"
);
const employeeTotalSpanElem = document.getElementById("employee-total-price");
const employeeTotalSentenceSpanElem = document.getElementById(
  "employee-total-sentence"
);

const bikesWrap = document.getElementById("bikes-wrap");
const nbBikesInput = document.getElementById("nb-bikes");
const totalSavedCarbonElem = document.getElementById("total-saved-carbon");
const totalSavedCarbonUnitElem = document.getElementById(
  "total-saved-carbon-unit"
);
const savedCarbonVerbElem = document.getElementById("saved-carbon-verb");
const savedGasPriceElem = document.getElementById("saved-gas-price");
const savedGasSentenceElem = document.getElementById("saved-gas-sentence");

totalSavedCarbonUnitElem.textContent = "kg";
savedCarbonVerbElem.textContent = "économisés";

nbBikesInput.addEventListener("input", (event) => {
  if (event.target.value === "") {
    bikesNumber = 0;
  } else {
    bikesNumber = parseInt(event.target.value);
  }
  calculateSavedCarbon();
  calculateTotalPrice();
});
nbBikesInput.value = bikesNumber;
calculateSavedCarbon();

async function calculateSavedCarbon() {
  let totalSavedCarbon = bikesNumber * 707;
  let totalEmittedCarbon = bikesNumber * 35;
  totalSavedCarbon = totalSavedCarbon - totalEmittedCarbon;
  totalSavedCarbonUnitElem.textContent = "kg";
  savedCarbonVerbElem.textContent = "économisés";
  if (totalSavedCarbon > 1000) {
    totalSavedCarbon = totalSavedCarbon / 1000;
    totalSavedCarbonUnitElem.textContent = "tonnes";
    savedCarbonVerbElem.textContent = "économisées";
  }
  totalSavedCarbonElem.textContent = new Intl.NumberFormat("fr-FR").format(
    totalSavedCarbon
  );

  const carConsumptionPerKm = 0.07;
  const kilometersPerDay = 13;
  const workingDays = 20;
  const gasSavedPrice =
    (await avgGasPricePerKm) *
    carConsumptionPerKm *
    kilometersPerDay *
    workingDays;
  savedGasPriceElem.textContent = `${new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
  }).format(gasSavedPrice)}`;
  savedGasSentenceElem.textContent = " d'économies de carburant par mois *";
}

export function setEmployerShare(value) {
  employerShare = parseFloat(value);
  calculateTotalPrice();
}

var radios = document.querySelectorAll(
  'input[type=radio][name="employer-share"]'
);
radios.forEach((radio) =>
  radio.addEventListener("change", () => setEmployerShare(radio.value))
);

const pkgCards = packages.map((pkg, index) => {
  const pkgCard = createPackageCard(pkg, index);
  if (index === 0) pkgCard.classList.add("selected");
  bikesWrap.appendChild(pkgCard);
  return pkgCard;
});
calculateTotalPrice();

function createPackageCard(pkg, pkgNumber) {
  const card = document.createElement("div");
  card.className = "pkg-card";

  card.addEventListener("click", (event) => {
    const source = event.currentTarget;
    if (!source.classList.contains("selected")) {
      pkgCards.forEach((pkgCard) => {
        pkgCard.classList.remove("selected");
      });
      source.classList.add("selected");
      selectedPackage = packages[pkgNumber];

      calculateTotalPrice();
    }
  });

  const title = document.createElement("span");
  title.textContent = pkg.name;
  title.className = "title";

  const img = document.createElement("img");
  img.setAttribute("width", 200);
  img.setAttribute("height", 120);
  img.setAttribute("src", pkg.img);

  const price = document.createElement("span");
  price.textContent = `${pkg.price} € HT/mois`;
  price.className = "bike-price";

  const desc = document.createElement("span");
  desc.textContent = pkg.description;
  desc.className = "bike-description";

  const shortDesc = document.createElement("span");
  shortDesc.textContent = pkg["short-desc"];
  shortDesc.className = "bike-short-desc";

  const descPriceSeparator = document.createElement("div");
  descPriceSeparator.className = "desc-price-separator";

  const imgDescWrap = document.createElement("div");
  imgDescWrap.className = "img-desc-wrap";

  card.appendChild(title);
  imgDescWrap.appendChild(img);
  imgDescWrap.appendChild(desc);
  imgDescWrap.appendChild(shortDesc);
  card.appendChild(imgDescWrap);
  card.appendChild(price);

  return card;
}

function calculateTotalPrice() {
  total = selectedPackage.price * bikesNumber;

  employerTotal = total * employerShare * 0.75;
  employerTotalSpanElem.textContent = new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
  }).format(employerTotal);

  employerTotalTaxed = employerTotal * 1.2;
  employerTotalTaxedSpanElem.textContent = new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
  }).format(employerTotalTaxed);

  const monthlyEmployerPerBike = employerTotal / bikesNumber;
  employerTotalPerBikeSpanElem.textContent = new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
  }).format(monthlyEmployerPerBike);

  const monthlyPerBike = selectedPackage.price * employerShare;
  employeeTotal = selectedPackage.price - monthlyPerBike;
  const taxes = employeeTotal * 0.2;
  employeeTotal = employeeTotal + taxes;

  employeeTotalSpanElem.textContent =
    employeeTotal === 0
      ? "Loyer gratuit"
      : `${new Intl.NumberFormat("fr-FR", {
          style: "currency",
          currency: "EUR",
        }).format(employeeTotal)} `;
  employeeTotalSentenceSpanElem.textContent = "de participation au loyer";
}
