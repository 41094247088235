[
  {
    "name": "URBAN BASIC",
    "price": "79",
    "description": "Pour les trajets courts.\n\nComprend les indispensables de sécurité (casque et gilet) ainsi que la garantie casse/vol et la maintenance.",
    "short-desc": "Basic, simple et efficace",
    "img": "https://api.azfalte.com/v1/products/7e56b72f-3125-42ed-a5f2-bf9a6e55ac7b/image?w=200"
  },
  {
    "name": "URBAN CLASSIC",
    "price": "90",
    "description": "Pour les usages fréquents et les distances plus élevées et/ou avec du dénivelé.\n\nComprend les indispensables de sécurité (casque et gilet) ainsi que la garantie casse/vol et la maintenance.",
    "short-desc": "Classic, fréquence et performance",
    "img": "https://api.azfalte.com/v1/products/3c9d3b31-3586-47f4-b410-3216b21f3154/image?w=200"
  },
  {
    "name": "URBAN STYLE",
    "price": "110",
    "description": "Pour les usages fréquents le tout avec des lignes épurées et bien souvent connecté.\n\nComprend les indispensables de sécurité (casque et gilet) ainsi que la garantie casse/vol et la maintenance.",
    "short-desc": "Style, look et performance",
    "img": "https://api.azfalte.com/v1/products/880e0e6a-beb5-4b93-96d6-14063c01e49c/image?w=200"
  }
]
