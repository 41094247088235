const axios = require("axios");
let averagePrice;

async function main() {
  const apiURL =
    "https://data.economie.gouv.fr/api/records/1.0/search/?dataset=prix-carburants-fichier-instantane-test-ods-copie&q=&rows=500&refine.prix_nom=Gazole";
  const apiResult = await axios.get(apiURL);

  const prices = apiResult.data.records.map(
    (record) => record.fields.prix_valeur
  );
  const totalPrice = prices.reduce((prev, curr) => {
    return prev + curr;
  }, 0);

  averagePrice = totalPrice / 500;
  console.log(averagePrice);
  return averagePrice;
}

export { main };
